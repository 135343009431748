

// import React, { useState, useCallback } from 'react';
// import { addHorse } from '../services/adminService';
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Paper,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import Cropper from 'react-easy-crop';
// import { format, addMinutes } from 'date-fns';
// import { getCroppedImg } from '../pages/cropUtils'; // your utility for cropping

// function AddHorsePage() {
//   const [horseName, setHorseName] = useState('');
//   const [cost, setCost] = useState('');
//   const [imageFile, setImageFile] = useState(null);
//   const [birthDate, setBirthDate] = useState('');

//   // For the crop modal
//   const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
//   const [imageSrc, setImageSrc] = useState(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

//   // handleAddHorse: final submit to create a new horse
//   const handleAddHorse = async (e) => {
//     e.preventDefault();

//     try {
//       const formData = new FormData();
//       formData.append('horseName', horseName);
//       formData.append('cost', parseFloat(cost));
//       formData.append('Gender', 'm');
//       formData.append('BirthDate', birthDate);

//       if (imageFile) {
//         formData.append('ImageURL', imageFile); // 'ImageURL' or rename as you want
//       }

//       await addHorse(formData);
//       alert('Horse added successfully');
//       setHorseName('');
//       setCost('');
//       setImageFile(null);
//       setBirthDate('');
//     } catch (err) {
//       alert(`Error adding horse: ${err.response?.data || 'An error occurred'}`);
//       console.error(err);
//     }
//   };

//   // When the user picks a file from their system
//   const onFileSelect = (e) => {
//     const file = e.target.files[0];
//     if (!file) return;
//     const reader = new FileReader();
//     reader.onload = () => {
//       setImageSrc(reader.result);
//       setIsCropDialogOpen(true); // open crop modal
//     };
//     reader.readAsDataURL(file);
//   };

//   // react-easy-crop's crop completion callback
//   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   }, []);

//   // Save the cropped image in memory as a Blob in `imageFile`.
//   const handleSaveCroppedImage = async () => {
//     try {
//       const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
//       if (!croppedBlob) {
//         alert('Failed to crop image. Try again.');
//         return;
//       }

//       // Convert the blob to a File object if you want a filename
//       const croppedFile = new File([croppedBlob], 'horseImage.jpg', { type: 'image/jpeg' });
//       setImageFile(croppedFile); // We'll upload this in handleAddHorse

//       // close dialog and reset crop
//       setIsCropDialogOpen(false);
//       setImageSrc(null);
//       setCrop({ x: 0, y: 0 });
//       setZoom(1);
//       setCroppedAreaPixels(null);
//     } catch (err) {
//       console.error('Error cropping image:', err);
//       alert('Error cropping image. Check console for details.');
//     }
//   };

//   return (
//     <Container maxWidth="sm">
//       <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
//         <Typography variant="h5" gutterBottom>
//           Add New Horse
//         </Typography>
//         <form onSubmit={handleAddHorse}>
//           <TextField
//             label="Horse Name"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={horseName}
//             onChange={(e) => setHorseName(e.target.value)}
//             required
//           />
//           <TextField
//             label="Cost"
//             type="number"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={cost}
//             onChange={(e) => setCost(e.target.value)}
//             required
//             inputProps={{ min: 0, step: '0.01' }}
//           />
//           <TextField
//             label="Birth Date"
//             type="date"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={birthDate}
//             onChange={(e) => setBirthDate(e.target.value)}
//             required
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />

//           {/* Image Upload (with cropping) */}
//           <input
//             accept="image/*"
//             style={{ display: 'none' }}
//             id="horse-image-upload"
//             type="file"
//             onChange={onFileSelect}
//           />
//           <label htmlFor="horse-image-upload">
//             <Button variant="contained" component="span" sx={{ mt: 1, mb: 1 }}>
//               {imageFile ? 'Change Image' : 'Upload Image'}
//             </Button>
//           </label>
//           {imageFile && (
//             <Typography variant="body2" sx={{ mb: 2 }}>
//               Selected file: {imageFile.name}
//             </Typography>
//           )}

//           <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
//             Add Horse
//           </Button>
//         </form>
//       </Paper>

//       {/* Crop Dialog */}
//       <Dialog
//         open={isCropDialogOpen}
//         onClose={() => setIsCropDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>Crop Image</DialogTitle>
//         <DialogContent style={{ position: 'relative', height: 400 }}>
//           {imageSrc && (
//             <Cropper
//               image={imageSrc}
//               crop={crop}
//               zoom={zoom}
//               aspect={4 / 3} // Adjust aspect ratio if desired
//               onCropChange={setCrop}
//               onCropComplete={onCropComplete}
//               onZoomChange={setZoom}
//             />
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsCropDialogOpen(false)} color="inherit">
//             Cancel
//           </Button>
//           <Button onClick={handleSaveCroppedImage} variant="contained" color="primary">
//             Save Image
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// }

// export default AddHorsePage;




import React, { useState, useCallback } from 'react';
import { addHorse } from '../services/adminService';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../pages/cropUtils'; // your utility for cropping

function AddHorsePage() {
  const [horseName, setHorseName] = useState('');
  const [cost, setCost] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('m'); // default to 'm' or 'f'

  // For the crop modal
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // handleAddHorse: final submit to create a new horse
  const handleAddHorse = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('horseName', horseName);
      formData.append('cost', parseFloat(cost));
      formData.append('Gender', gender);    // Use the selected radio value
      formData.append('BirthDate', birthDate);

      if (imageFile) {
        formData.append('ImageURL', imageFile);
      }

      await addHorse(formData);
      alert('Horse added successfully');
      setHorseName('');
      setCost('');
      setImageFile(null);
      setBirthDate('');
      setGender('m'); // reset to default if you want
    } catch (err) {
      alert(`Error adding horse: ${err.response?.data || 'An error occurred'}`);
      console.error(err);
    }
  };

  // When the user picks a file from their system
  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
      setIsCropDialogOpen(true);
    };
    reader.readAsDataURL(file);
  };

  // react-easy-crop's crop completion callback
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Save the cropped image as a Blob in `imageFile`.
  const handleSaveCroppedImage = async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      if (!croppedBlob) {
        alert('Failed to crop image. Try again.');
        return;
      }
      const croppedFile = new File([croppedBlob], 'horseImage.jpg', { type: 'image/jpeg' });
      setImageFile(croppedFile);

      setIsCropDialogOpen(false);
      setImageSrc(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
    } catch (err) {
      console.error('Error cropping image:', err);
      alert('Error cropping image. Check console for details.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Add New Horse
        </Typography>
        <form onSubmit={handleAddHorse}>
          <TextField
            label="Horse Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={horseName}
            onChange={(e) => setHorseName(e.target.value)}
            required
          />
          <TextField
            label="Cost"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
            inputProps={{ min: 0, step: '0.01' }}
          />
          <TextField
            label="Birth Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/* GENDER SELECTION */}
          <FormControl component="fieldset" sx={{ mt: 2, mb: 2 }}>
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              row
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel value="m" control={<Radio />} label="Male" />
              <FormControlLabel value="f" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>

          {/* Image Upload (with cropping) */}
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="horse-image-upload"
            type="file"
            onChange={onFileSelect}
          />
          <label htmlFor="horse-image-upload">
            <Button variant="contained" component="span" sx={{ mt: 1, mb: 1 }}>
              {imageFile ? 'Change Image' : 'Upload Image'}
            </Button>
          </label>
          {imageFile && (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Selected file: {imageFile.name}
            </Typography>
          )}

          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Add Horse
          </Button>
        </form>
      </Paper>

      {/* Crop Dialog */}
      <Dialog
        open={isCropDialogOpen}
        onClose={() => setIsCropDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent style={{ position: 'relative', height: 400 }}>
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCropDialogOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleSaveCroppedImage} variant="contained" color="primary">
            Save Image
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AddHorsePage;
