// admin/src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminLoginPage from './pages/AdminLoginPage';
import DashboardPage from './pages/DashboardPage';
import AddHorsePage from './pages/AddHorsePage';
import UsersListPage from './pages/UsersListPage';
import UserTransactionsPage from './pages/UserTransactionsPage';
import AdminReservationsPage from './pages/AdminReservationsPage';

import CreateUserPage from './pages/CreateUserPage';
import UpdateHorseCostPage from './pages/UpdateHorseCostPage';
import ResetUserPasswordPage from './pages/ResetUserPasswordPage'; // Import the new page
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';
import {jwtDecode} from 'jwt-decode';



function App() {
  const [token, setToken] = useState(localStorage.getItem('adminToken') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const saveToken = (adminToken) => {
    localStorage.setItem('adminToken', adminToken);
    setToken(adminToken);
  };


  const theme = createTheme({
    palette: {
      type: 'dark', // Use dark mode
      primary: {
        main: '#888', // Customize your primary color
      },
      background: {
        default: '888', 
        // Background color for the app
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });




  const logout = () => {
    localStorage.removeItem('adminToken');
    setToken('');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          logout();
        } else {
          setIsAuthenticated(true);
        }
      } catch (err) {
        console.error(err);
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);









  if (!token) {
    return (
      <ThemeProvider theme={theme}>
        <AdminLoginPage setToken={saveToken} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                background: 'url(/images/horsesclub.png)',
        backgroundRepeat: 'no-repeat',
    backgroundSize: 'initial',
    backgroundPosition: 'center',


      },
    }}
  />
      <Router>
      {isAuthenticated ? (
          <Routes>
            <Route path="/" element={<DashboardPage logout={logout} />} />
            <Route path="/create-user" element={<CreateUserPage />} />
            <Route path="/update-horse-cost" element={<UpdateHorseCostPage />} />
            <Route path="/reset-user-password" element={<ResetUserPasswordPage />} />
            <Route path="/add-horse" element={<AddHorsePage />} /> {/* Add this line */}
            <Route path="/reservations" element={<AdminReservationsPage />} />
            <Route path="/users" element={<UsersListPage />} />
            <Route path="/users/:userId/transactions" element={<UserTransactionsPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<AdminLoginPage setToken={saveToken} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;




