// admin/src/pages/CreateUserPage.js

import React, { useState } from 'react';
import { createUser } from '../services/adminService';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
} from '@mui/material';

function CreateUserPage({token, logout }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [balance, setBalance] = useState('');
  const [email, setEmail] = useState('');

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await createUser({
        firstName,
        lastName,
        mobileNumber,
        email,
        password,
        balance: parseFloat(balance),
      });
      alert('User created successfully');
      // Reset form fields
      setFirstName('');
      setLastName('');
      setMobileNumber('');
      setPassword('');
      setBalance('');
      setEmail('');

    } catch (err) {
      alert(`Error creating user: ${err.response?.data || 'An error occurred'}`);
      console.error(err);
    }
  };
  

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Create New User
        </Typography>
        <form onSubmit={handleCreateUser}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            label="Mobile Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            label="Balance"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            required
            inputProps={{ min: 0, step: '0.01' }}
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Create User
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateUserPage;
