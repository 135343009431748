


// admin/src/pages/AdminLoginPage.js

import React, { useState } from 'react';
import { adminLogin } from '../services/adminService';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Avatar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  avatar: {
    margin: '0 auto',
    width: '50%',
    height: 'auto',
    borderRadius: '50%',
  },
}));

function AdminLoginPage({ setToken }) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleAdminLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await adminLogin(username, password);
      setToken(response.data.token);
    } catch (err) {
      alert('Login failed');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} className={classes.paper}>
        <Avatar
          alt="Logo"
          src='../logo512.png'
          className={classes.avatar}
          variant="square"
        />
        <Typography variant="h5" gutterBottom>
          Admin Login
        </Typography>
        <form onSubmit={handleAdminLogin}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default AdminLoginPage;

