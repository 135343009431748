import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Snackbar,
  Alert,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { format } from 'date-fns';
import { getReservations, cancelReservation } from '../services/adminService';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function AdminReservationsPage() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dateFilter, setDateFilter] = useState(''); // YYYY-MM-DD
  const [monthFilter, setMonthFilter] = useState(''); // YYYY-MM
  const [searchTerm, setSearchTerm] = useState('');

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ key: 'ReservationDate', direction: 'asc' });

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Reason input for cancelation
  const [isReasonDialogOpen, setIsReasonDialogOpen] = useState(false);
  const [currentReservation, setCurrentReservation] = useState(null);
  const [reason, setReason] = useState('');

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch reservations from server
  const fetchReservations = async () => {
    setLoading(true);
    try {
      const params = {};
      if (dateFilter) {
        params.date = dateFilter;
      } else if (monthFilter) {
        params.month = monthFilter;
      }
      const response = await getReservations(params);
      setReservations(response.data);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      setSnackbar({ open: true, message: 'Failed to fetch reservations', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = () => {
    fetchReservations();
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  // Cancel reservation logic
  const handleCancelClick = (reservation) => {
    setCurrentReservation(reservation);
    setReason('');
    setIsReasonDialogOpen(true);
  };

  const handleConfirmCancel = async () => {
    if (!reason.trim()) {
      alert('Please enter a reason.');
      return;
    }
    try {
      await cancelReservation(currentReservation.ReservationID, reason);
      setSnackbar({ open: true, message: 'Reservation cancelled successfully', severity: 'success' });
      setIsReasonDialogOpen(false);
      setCurrentReservation(null);
      setReason('');
      fetchReservations();
    } catch (error) {
      console.error('Error cancelling reservation:', error);
      setSnackbar({ open: true, message: 'Failed to cancel reservation', severity: 'error' });
    }
  };

  // Client-side search
  const filteredReservations = reservations.filter((res) => {
    const firstName = (res.FirstName || '').toLowerCase();
    const lastName = (res.LastName || '').toLowerCase();
    const horseName = (res.HorseName || '').toLowerCase();
    const dateStr = format(new Date(res.ReservationDate), 'yyyy-MM-dd');
    const startTime = (res.StartTime || '').toLowerCase();
    const endTime = (res.EndTime || '').toLowerCase();
    const reasonField = (res.Reason || '').toLowerCase();
    const statusField = (res.Status || '').toLowerCase();

    const combinedString = [
      firstName,
      lastName,
      horseName,
      dateStr,
      startTime,
      endTime,
      reasonField,
      statusField,
    ].join(' ').toLowerCase();

    return combinedString.includes(searchTerm.toLowerCase());
  });

  // Sorting logic
  const sortData = (data, config) => {
    const { key, direction } = config;
    return [...data].sort((a, b) => {
      let aVal = a[key];
      let bVal = b[key];

      // Example: if sorting by 'ReservationDate' or some date-like field
      if (key === 'ReservationDate') {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      } else if (typeof aVal === 'string') {
        aVal = aVal.toLowerCase();
        bVal = bVal.toLowerCase();
      }

      // If sorting by "Actions", we interpret cancelled vs. active as string compare
      // or perhaps you'd compare boolean or something else.
      if (key === 'Actions') {
        // For example: 'cancelled' sorts after 'active', or vice versa.
        // Or define your custom logic
        aVal = a.Status === 'cancelled' ? 'cancelled' : 'active';
        bVal = b.Status === 'cancelled' ? 'cancelled' : 'active';
      }

      if (aVal < bVal) return direction === 'asc' ? -1 : 1;
      if (aVal > bVal) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const sortedReservations = sortData(filteredReservations, sortConfig);

  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnKey, direction });
  };

  // Export to Excel
  const handleExportToExcel = () => {
    const exportData = sortedReservations.map((res) => ({
      UserName: `${res.FirstName} ${res.LastName}`,
      HorseName: res.HorseName,
      Date: format(new Date(res.ReservationDate), 'yyyy-MM-dd'),
      StartTime: res.StartTime,
      EndTime: res.EndTime,
      Status: res.Status,
      Reason: res.Reason || '',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reservations');
    const wbOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbOut], { type: 'application/octet-stream' });
    saveAs(blob, 'reservations.xlsx');
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        All Reservations
      </Typography>

      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Filter Reservations
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Filter by Date"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dateFilter}
              onChange={(e) => {
                setDateFilter(e.target.value);
                setMonthFilter('');
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Filter by Month (YYYY-MM)"
              type="text"
              variant="outlined"
              fullWidth
              placeholder="YYYY-MM"
              value={monthFilter}
              onChange={(e) => {
                setMonthFilter(e.target.value);
                setDateFilter('');
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={handleFilter} fullWidth sx={{ mt: 0 }}>
              Filter
            </Button>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Search All Fields
        </Typography>
        <TextField
          label="Search..."
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search user, horse, date, etc."
        />
      </Paper>

      <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={handleExportToExcel}
        disabled={sortedReservations.length === 0}
      >
        Export to Excel
      </Button>

      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={sortConfig.key === 'FirstName' ? sortConfig.direction : false}>
                  <TableSortLabel
                    active={sortConfig.key === 'FirstName'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('FirstName')}
                  >
                    User Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortConfig.key === 'HorseName' ? sortConfig.direction : false}>
                  <TableSortLabel
                    active={sortConfig.key === 'HorseName'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('HorseName')}
                  >
                    Horse Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortConfig.key === 'ReservationDate' ? sortConfig.direction : false}>
                  <TableSortLabel
                    active={sortConfig.key === 'ReservationDate'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('ReservationDate')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell sortDirection={sortConfig.key === 'Status' ? sortConfig.direction : false}>
                  <TableSortLabel
                    active={sortConfig.key === 'Status'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('Status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                {/* Reason is NO longer sortable, so remove TableSortLabel */}
                <TableCell>Reason</TableCell>
                {/* Actions is now sortable, let's store them as 'Actions' */}
                <TableCell
                  align="center"
                  sortDirection={sortConfig.key === 'Actions' ? sortConfig.direction : false}
                >
                  <TableSortLabel
                    active={sortConfig.key === 'Actions'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('Actions')}
                  >
                    Actions
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedReservations.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No reservations found.
                  </TableCell>
                </TableRow>
              ) : (
                sortedReservations.map((res) => {
                  const dateStr = format(new Date(res.ReservationDate), 'yyyy-MM-dd');
                  return (
                    <TableRow key={res.ReservationID}>
                      <TableCell>
                        {res.FirstName} {res.LastName}
                      </TableCell>
                      <TableCell>{res.HorseName}</TableCell>
                      <TableCell>{dateStr}</TableCell>
                      <TableCell>{res.StartTime}</TableCell>
                      <TableCell>{res.EndTime}</TableCell>
                      <TableCell>{res.Status}</TableCell>
                      <TableCell>{res.Reason || ''}</TableCell>
                      <TableCell align="center">
                        {res.Status === 'cancelled' ? (
                          <Button variant="outlined" disabled>
                            cancelled
                          </Button>
                        ) : (
                          <Button variant="outlined" color="error" onClick={() => handleCancelClick(res)}>
                            Cancel
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Paper>
      )}

      {/* Reason Dialog */}
      <Dialog open={isReasonDialogOpen} onClose={() => setIsReasonDialogOpen(false)}>
        <DialogTitle>Reason for Cancellation</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason"
            fullWidth
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsReasonDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleConfirmCancel}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminReservationsPage;
