// // admin/src/pages/UsersListPage.js

// import React, { useState, useEffect } from 'react';
// import { getAllUsers } from '../services/adminService';
// import {
//   Container,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Button,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';

// function UsersListPage() {
//   const [users, setUsers] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await getAllUsers();
//         setUsers(response.data);
//       } catch (err) {
//         console.error(err);
//       }
//     };

//     fetchUsers();
//   }, []);

//   const handleViewTransactions = (userId) => {
//     navigate(`/users/${userId}/transactions`);
//   };

//   return (
//     <Container maxWidth="md">
//       <Typography variant="h5" gutterBottom>
//         Users List
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>UserID</TableCell>
//               <TableCell>First Name</TableCell>
//               <TableCell>Last Name</TableCell>
//               <TableCell>Mobile Number</TableCell>
//               <TableCell>Balance</TableCell>
//               <TableCell align="center">Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {users.map((user) => (
//               <TableRow key={user.UserID}>
//                 <TableCell>{user.UserID}</TableCell>
//                 <TableCell>{user.FirstName}</TableCell>
//                 <TableCell>{user.LastName}</TableCell>
//                 <TableCell>{user.MobileNumber}</TableCell>
//                 <TableCell>{user.Balance}</TableCell>
//                 <TableCell align="center">
//                   <Button
//                     variant="contained"
//                     size="small"
//                     onClick={() => handleViewTransactions(user.UserID)}
//                   >
//                     View Transactions
//                   </Button>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Container>
//   );
// }

// export default UsersListPage;



// admin/src/pages/UsersListPage.js

import React, { useState, useEffect } from 'react';
import { getAllUsers } from '../services/adminService';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function UsersListPage() {
  const [users, setUsers] = useState([]); // Original list of users from the backend
  const [searchTerm, setSearchTerm] = useState(''); // Search term entered by the admin
  const [filteredUsers, setFilteredUsers] = useState([]); // Users filtered based on the search term
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        setUsers(response.data);
        setFilteredUsers(response.data); // Initialize filtered users
      } catch (err) {
        console.error(err);
      }
    };

    fetchUsers();
  }, []);

  // Handle search input changes
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === '') {
      // If search term is empty, show all users
      setFilteredUsers(users);
    } else {
      // Filter users based on the search term
      const filtered = users.filter((user) => {
        const fullName = `${user.FirstName} ${user.LastName}`.toLowerCase();
        return (
          user.FirstName.toLowerCase().includes(term.toLowerCase()) ||
          user.LastName.toLowerCase().includes(term.toLowerCase()) ||
          user.MobileNumber.toLowerCase().includes(term.toLowerCase()) ||
          user.Balance.toString().includes(term) ||
          fullName.includes(term.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
    }
  };

  const handleViewTransactions = (userId) => {
    navigate(`/users/${userId}/transactions`);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" gutterBottom>
        Users List
      </Typography>

      {/* Search Input */}
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <TextField
          label="Search Users"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>UserID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.UserID}>
                <TableCell>{user.UserID}</TableCell>
                <TableCell>{user.FirstName}</TableCell>
                <TableCell>{user.LastName}</TableCell>
                <TableCell>{user.MobileNumber}</TableCell>
                <TableCell>{user.Balance}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleViewTransactions(user.UserID)}
                  >
                    View Transactions
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {filteredUsers.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default UsersListPage;
