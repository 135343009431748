

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CreateIcon from '@mui/icons-material/Create';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LockResetIcon from '@mui/icons-material/LockReset';
import PeopleIcon from '@mui/icons-material/People';

const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      flexGrow: 1,
      minHeight: '100vh',
      backgroundImage: 'url(/images/horsesclub.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'center',
      backgroundPosition: 'center',
      padding: 50,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay
        zIndex: 1,
      },
    },
    content: {
      position: 'relative',
      zIndex: 2,
    },
    card: {
      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
      color: '#000',
    },
    icon: {
      fontSize: 48,
      marginBottom: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
      color: '#000',
    },
  }));

function DashboardPage({ logout }) {
  const classes = useStyles();

  const adminOptions = [
    {
      title: 'Create User',
      icon: <CreateIcon className={classes.icon} />,
      link: '/create-user',
    },
    {
      title: 'Update Horse Cost',
      icon: <MonetizationOnIcon className={classes.icon} />,
      link: '/update-horse-cost',
    },
    {
      title: 'Reset User Password',
      icon: <LockResetIcon className={classes.icon} />,
      link: '/reset-user-password',
    },


    {
      title: 'View All Reservations',
      icon: <LockResetIcon className={classes.icon} />,
      link: '/reservations',
    },

    {
      title: 'Add New Horse',
      icon: <CreateIcon className={classes.icon} />,
      link: '/add-horse',
    },
    {
      title: 'View All Users',
      icon: <PeopleIcon className={classes.icon} />,
      link: '/users',
    },
  ];

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.content}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: '#fff', fontWeight: 'bold' }}
          >
            Admin Dashboard
          </Typography>
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </div>
        <Grid container spacing={4}>
          {adminOptions.map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.title}>
              <Card className={classes.card}>
                <CardActionArea component={Link} to={option.link}>
                  <CardContent style={{ textAlign: 'center' }}>
                    {option.icon}
                    <Typography variant="h6" className={classes.title}>
                      {option.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default DashboardPage;

