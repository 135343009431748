// admin/src/pages/ResetUserPasswordPage.js

import React, { useState } from 'react';
import { resetUserPassword } from '../services/adminService';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
} from '@mui/material';

function ResetUserPasswordPage({ token }) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');





  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetUserPassword(mobileNumber, newPassword);
      alert('Password reset successfully');
      setMobileNumber('');
      setNewPassword('');
    } catch (err) {
      alert(`Error resetting password: ${err.response?.data || 'An error occurred'}`);
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Reset User Password
        </Typography>
        <form onSubmit={handleResetPassword}>
          <TextField
            label="Mobile Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Reset Password
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default ResetUserPasswordPage;
