// // admin/src/pages/UpdateHorseCostPage.js
// import React, { useState, useEffect } from 'react';
// import { getHorses, updateHorseCost } from '../services/adminService';
// // import axios from 'axios';
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Paper,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
// } from '@mui/material';

// function UpdateHorseCostPage({ token }) {
//   const [horses, setHorses] = useState([]);
//   const [selectedHorse, setSelectedHorse] = useState('');
//   const [cost, setCost] = useState('');

//   useEffect(() => {
//     const fetchHorses = async () => {
//       try {
//         const response = await getHorses();
//         setHorses(response.data);
//       } catch (err) {
//         console.error(err);
//       }
//     };

//     fetchHorses();
//   }, []);

//   const handleUpdateCost = async (e) => {
//     e.preventDefault();
//     try {
//       await updateHorseCost(selectedHorse, parseFloat(cost));
//       alert('Horse cost updated successfully');
//       setSelectedHorse('');
//       setCost('');
//     } catch (err) {
//       console.error(err);
//       alert('Error updating horse cost');
//     }
//   };

//   return (
//     <Container maxWidth="sm">
//       <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
//         <Typography variant="h5" gutterBottom>
//           Update Horse Cost
//         </Typography>
//         <form onSubmit={handleUpdateCost}>
//           <FormControl fullWidth margin="normal">
//             <InputLabel>Select Horse</InputLabel>
//             <Select
//               value={selectedHorse}
//               onChange={(e) => setSelectedHorse(e.target.value)}
//               required
//             >
//               {horses.map((horse) => (
//                 <MenuItem key={horse.HorseID} value={horse.HorseID}>
//                   {horse.HorseName}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <TextField
//             label="Cost"
//             type="number"
//             fullWidth
//             margin="normal"
//             value={cost}
//             onChange={(e) => setCost(e.target.value)}
//             required
//           />
//           <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
//             Update Cost
//           </Button>
//         </form>
//       </Paper>
//     </Container>
//   );
// }

// export default UpdateHorseCostPage;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Cropper from 'react-easy-crop';
import { getHorses, updateHorseCost, deleteHorse, updateHorseImage } from '../services/adminService';
import { getCroppedImg } from '../pages/cropUtils'; // We'll create this utility below

function UpdateHorseCostPage() {
  const [horses, setHorses] = useState([]);
  const [selectedHorse, setSelectedHorse] = useState('');
  const [cost, setCost] = useState('');

  // For image cropping
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    const fetchHorsesData = async () => {
      try {
        const response = await getHorses();
        setHorses(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchHorsesData();
  }, []);

  const handleUpdateCost = async (e) => {
    e.preventDefault();
    try {
      await updateHorseCost(selectedHorse, parseFloat(cost));
      alert('Horse cost updated successfully');
      setSelectedHorse('');
      setCost('');
    } catch (err) {
      console.error(err);
      alert('Error updating horse cost');
    }
  };

  // DELETE HORSE
  const handleDeleteHorse = async () => {
    if (!selectedHorse) {
      alert('Please select a horse first');
      return;
    }
    if (window.confirm('Are you sure you want to delete this horse?')) {
      try {
        await deleteHorse(selectedHorse);
        alert('Horse deleted successfully');
        // Refresh horses list
        const refreshed = await getHorses();
        setHorses(refreshed.data);
        setSelectedHorse('');
      } catch (err) {
        console.error(err);
        alert('Error deleting horse');
      }
    }
  };

  // IMAGE UPLOAD & CROP
  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
      setIsCropDialogOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSaveCroppedImage = async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      if (!croppedBlob) {
        alert('Could not crop the image. Try again.');
        return;
      }
      // Send blob as FormData to updateHorseImage
      const formData = new FormData();
      formData.append('image', croppedBlob, 'horseImage.jpg'); // any filename
      await updateHorseImage(selectedHorse, formData);
      alert('Horse image updated successfully!');
    } catch (err) {
      console.error(err);
      alert('Error updating horse image');
    } finally {
      setIsCropDialogOpen(false);
      setImageSrc(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Update Horse Cost
        </Typography>
        <form onSubmit={handleUpdateCost}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Horse</InputLabel>
            <Select
              value={selectedHorse}
              onChange={(e) => setSelectedHorse(e.target.value)}
              required
            >
              {horses.map((horse) => (
                <MenuItem key={horse.HorseID} value={horse.HorseID}>
                  {horse.HorseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Cost"
            type="number"
            fullWidth
            margin="normal"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Update Cost
          </Button>
        </form>
        <Button 
          variant="outlined" 
          color="error" 
          fullWidth 
          sx={{ mt: 2 }} 
          onClick={handleDeleteHorse}
          disabled={!selectedHorse}
        >
          Delete Selected Horse
        </Button>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Update Horse Image
        </Typography>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="horse-image-upload"
          type="file"
          onChange={onFileChange}
        />
        <label htmlFor="horse-image-upload">
          <Button
            variant="contained"
            component="span"
            fullWidth
            disabled={!selectedHorse}
            sx={{ mt: 1 }}
          >
            Upload & Crop New Image
          </Button>
        </label>
      </Paper>

      {/* Crop Dialog */}
      <Dialog open={isCropDialogOpen} onClose={() => setIsCropDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent style={{ position: 'relative', height: 400 }}>
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3} // Adjust aspect ratio as needed
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCropDialogOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleSaveCroppedImage} variant="contained" color="primary">
            Save Image
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UpdateHorseCostPage;
