// admin/src/utils/cropUtils.js

/**
 * Creates a canvas and returns a blob of the cropped area.
 * 
 * @param {string} imageSrc - base64 or URL of the original image
 * @param {object} croppedAreaPixels - from react-easy-crop onCropComplete
 * @returns {Promise<Blob>} - the cropped image blob
 */
export async function getCroppedImg(imageSrc, croppedAreaPixels) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
  
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      width,
      height
    );
  
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg', 0.9);
    });
  }
  
  function createImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', (err) => reject(err));
      img.setAttribute('crossOrigin', 'anonymous'); // needed for cross-origin images
      img.src = url;
    });
  }
  